
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import GraphDesignElements from '@/components/shared/graph/GraphDesignElements.vue'
import useSmsSurvey from '@/compositions/services/useSmsSurvey'

export default defineComponent({
  name: 'GraphBranchNextStep',
  components: {
    GraphDesignElements,
    TmDropdownItem,
    TmDropdown,
    TmButton,
  },
  setup() {
    const { openAddQuestionSurveyModal, openAddDelaySurveyModal } = useSmsSurvey()

    return {
      openAddQuestionSurveyModal,
      openAddDelaySurveyModal,
    }
  },
})
