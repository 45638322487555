import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createSlots as _createSlots, createVNode as _createVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-727d5a72"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "graph-branch" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = {
  key: 1,
  class: "graph-branch__children-wrapper"
}
const _hoisted_4 = { class: "graph-branch__children" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_graph_item_row = _resolveComponent("graph-item-row")!
  const _component_graph_item = _resolveComponent("graph-item")!
  const _component_graph_branch_add_reply = _resolveComponent("graph-branch-add-reply")!
  const _component_graph_design_elements = _resolveComponent("graph-design-elements")!
  const _component_graph_branch_splitter = _resolveComponent("graph-branch-splitter")!
  const _component_graph_branch = _resolveComponent("graph-branch", true)!
  const _component_graph_branch_end_step = _resolveComponent("graph-branch-end-step")!
  const _component_graph_branch_next_step = _resolveComponent("graph-branch-next-step")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.branchItems, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_graph_item, {
            "item-type": item.itemType,
            name: item.name,
            "custom-icon": item.customIcon,
            "custom-icon-size": item.customIconSize,
            dropdown: item.dropdown,
            "content-text": item.contentText
          }, _createSlots({ _: 2 }, [
            (item.rows)
              ? {
                  name: "default",
                  fn: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.rows, (row, rowIndex) => {
                      return (_openBlock(), _createBlock(_component_graph_item_row, {
                        key: rowIndex,
                        name: row.name,
                        value: row.value
                      }, {
                        default: _withCtx(() => [
                          (row.slotData)
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent(row.slotData.componentName), _normalizeProps(_mergeProps({ key: 0 }, row.slotData.attrs)), {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(row.slotData.value), 1)
                                ]),
                                _: 2
                              }, 1040))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["name", "value"]))
                    }), 128))
                  ])
                }
              : undefined
          ]), 1032, ["item-type", "name", "custom-icon", "custom-icon-size", "dropdown", "content-text"]),
          (item.addReplay)
            ? (_openBlock(), _createBlock(_component_graph_branch_add_reply, { key: 0 }))
            : _createCommentVNode("", true)
        ]),
        (index !== _ctx.branchItems.length - 1)
          ? (_openBlock(), _createBlock(_component_graph_design_elements, {
              key: 0,
              element: "arrow-line"
            }))
          : _createCommentVNode("", true),
        (item.children && item.children.length)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_graph_branch_splitter, {
                "branches-count": item.children.length
              }, null, 8, ["branches-count"]),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.children, (childBranch, index) => {
                  return (_openBlock(), _createBlock(_component_graph_branch, {
                    key: index,
                    "branch-items": childBranch
                  }, null, 8, ["branch-items"]))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true),
        (item.endStep)
          ? (_openBlock(), _createBlock(_component_graph_branch_end_step, { key: 2 }))
          : _createCommentVNode("", true),
        (item.nextStep)
          ? (_openBlock(), _createBlock(_component_graph_branch_next_step, { key: 3 }))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}