
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import type { GraphBranchType } from '@/definitions/shared/graph/types'
import GraphItem from '@/components/shared/graph/GraphItem.vue'
import GraphItemRow from '@/components/shared/graph/GraphItemRow.vue'
import GraphBranchSplitter from '@/components/shared/graph/GraphBranchSplitter.vue'
import GraphBranchEndStep from '@/components/shared/graph/GraphBranchEndStep.vue'
import GraphBranchNextStep from '@/components/shared/graph/GraphBranchNextStep.vue'
import GraphBranchAddReply from '@/components/shared/graph/GraphBranchAddReply.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmTemplate from '@/components/shared/TmTemplate.vue'
import GraphDesignElements from '@/components/shared/graph/GraphDesignElements.vue'

export default defineComponent({
  name: 'GraphBranch',
  components: {
    GraphDesignElements,
    GraphBranchAddReply,
    GraphBranchNextStep,
    TmTemplate,
    TmButton,
    GraphBranchEndStep,
    GraphBranchSplitter,
    GraphItemRow,
    GraphItem,
  },
  props: {
    branchItems: {
      type: Array as PropType<GraphBranchType>,
    },
  },
})
