import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_graph_branch = _resolveComponent("graph-branch")!
  const _component_graph_board = _resolveComponent("graph-board")!

  return (_openBlock(), _createBlock(_component_graph_board, null, {
    default: _withCtx(() => [
      _createVNode(_component_graph_branch, { "branch-items": _ctx.mainBranchItems }, null, 8, ["branch-items"])
    ]),
    _: 1
  }))
}