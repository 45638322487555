
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'GraphBranchSplitter',
  props: {
    branchesCount: {
      type: Number,
    },
  },
})
