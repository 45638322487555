import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16f3e3fd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "graph-item-wrapper" }
const _hoisted_2 = { class: "graph-item" }
const _hoisted_3 = { class: "graph-item__header" }
const _hoisted_4 = {
  key: 0,
  class: "graph-item__content"
}
const _hoisted_5 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_survey_flow_dropdown = _resolveComponent("survey-flow-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_tm_icon, {
          name: _ctx.item.icon.name,
          size: _ctx.item.icon.size,
          class: "mr-2 text-primary"
        }, null, 8, ["name", "size"]),
        _createElementVNode("div", {
          class: _normalizeClass(_ctx.item.fontClass)
        }, _toDisplayString(_ctx.name), 3),
        (_ctx.dropdown)
          ? (_openBlock(), _createBlock(_component_survey_flow_dropdown, {
              key: 0,
              class: "ml-auto my-n1",
              items: _ctx.dropdown
            }, null, 8, ["items"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.$slots.default || _ctx.contentText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              _createElementVNode("div", {
                class: "graph-item__content-text",
                textContent: _toDisplayString(_ctx.contentText)
              }, null, 8, _hoisted_5)
            ], true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}