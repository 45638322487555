
import type { PropType } from 'vue'
import { defineComponent, computed } from 'vue'
import type { GraphItemTypeName, GraphDropdownType } from '@/definitions/shared/graph/types'
import SurveyFlowDropdown from '@/components/pages/services/smsSurveyFlow/SurveyFlowDropdown.vue'

type Item = {
  fontClass: string;
  icon: {
    name: string;
    size: string;
  }
}

export default defineComponent({
  components: { SurveyFlowDropdown },
  props: {
    itemType: {
      type: String as PropType<GraphItemTypeName>,
    },
    name: {
      type: String,
      required: true,
    },
    customIcon: {
      type: String,
      default: 'tmi-people',
    },
    customIconSize: {
      type: String,
      default: 'xxxLarge',
    },
    contentText: {
      type: String,
    },
    dropdown: {
      type: Array as PropType<GraphDropdownType[]>,
    },
  },
  setup(props) {
    const itemSettingsDefault: Item = {
      fontClass: 'subhead-semi-bold-16',
      icon: {
        name: props.customIcon,
        size: props.customIconSize,
      },
    }
    const itemSettings: Record<GraphItemTypeName, Item> = {
      question: {
        fontClass: 'subhead-semi-bold-16',
        icon: {
          name: 'send',
          size: 'xxxLarge',
        },
      },
      reply: {
        fontClass: 'body-text-semi-bold-14',
        icon: {
          name: 'question_answer',
          size: 'large',
        },
      },
      delay: {
        fontClass: 'body-text-semi-bold-14',
        icon: {
          name: 'watch_later',
          size: 'large',
        },
      },
    }
    const item = computed<Item>(() => props.itemType ? itemSettings[props.itemType] : itemSettingsDefault)

    return {
      item,
    }
  },
})
