
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

type DesignElement = 'dashed-line' | 'arrow-line'

export default defineComponent({
  name: 'GraphDesignElements',
  props: {
    element: {
      type: String as PropType<DesignElement>,
      required: true,
    },
    direction: {
      type: String as PropType<'top' | 'right' | 'bottom' | 'left' | 'vertical' | 'horizontal'>,
      default: 'bottom',
    },
    size: {
      type: String as PropType<'' | 'small'>,
      default: '',
    },
  },
})
