import { useModals } from '@/compositions/modals'

type UseSmsSurvey = () => {
  openNewModal: () => void
  openDuplicateModal: () => void
  openDeleteModal: (count: number) => void
  // survey flow
  openEditSurveyNameModal: () => void
  openDuplicateSurveyModal: () => void
  openScheduleSurveyModal: () => void
  openPreviewSurveyModal: () => void
  openResetSurveyModal: () => void
  openDeleteSurveyModal: () => void
  openRecipientsSurveyModal: () => void
  openAddQuestionSurveyModal: () => void
  openEditQuestionSurveyModal: () => void
  openDeleteQuestionSurveyModal: () => void
  openAddReplyModal: () => void
  openEditReplyModal: () => void
  openDeleteReplyModal: () => void
  openAddDelaySurveyModal: () => void
  openEditDelaySurveyModal: () => void
  openDeleteDelaySurveyModal: () => void
}

const useSmsSurvey: UseSmsSurvey = () => {
  const { openModal } = useModals()

  const openNewModal = () => {
    openModal('newSmsSurvey')
  }

  const openDuplicateModal = () => {
    openModal('confirmation', {
      title: 'Duplicate survey',
      text: 'Are you sure you would like to duplicate survey?',
      btnText: 'Duplicate survey',
    })
  }

  const openScheduleSurveyModal = () => {
    openModal('scheduleSurvey', {
      modalTitle: 'Schedule survey',
    })
  }

  const openPreviewSurveyModal = () => {
    openModal('previewSurvey', {
      modalTitle: 'Survey preview',
    })
  }

  const openDeleteModal = (count = 1) => {
    openModal('confirmation', {
      title: `Delete survey${count > 1 ? 's' : ''}`,
      text: `Are you sure you would like to delete ${count > 1 ? 'these' : 'this'} survey${count > 1 ? 's' : ''}?`,
      btnText: 'Delete',
      btnColor: 'red',
    })
  }

  // survey flow
  const openEditSurveyNameModal = () => {
    openModal('singleInput', {
      modalTitle: 'Edit survey name',
      label: 'Name',
      placeholder: 'Surveys name',
    })
  }

  const openDuplicateSurveyModal = () => {
    openModal('confirmation', {
      title: 'Duplicate survey',
      btnText: 'Duplicate survey',
      text: 'Are you sure you would like to duplicate survey?',
    })
  }

  const openResetSurveyModal = () => {
    openModal('confirmation', {
      title: 'Reset survey flow',
      btnText: 'Reset',
      btnColor: 'red',
      text: [
        { text: 'Are you sure you would like to reset flow for this survey?\nThe whole flow will be deleted and this change ' },
        { text: 'cannot be undone', style: 'semi-bold' },
        { text: '.' },
      ],
    })
  }

  const openDeleteSurveyModal = () => {
    openModal('confirmation', {
      title: 'Delete survey',
      btnText: 'Delete',
      btnColor: 'red',
      text: 'Are you sure you would like to delete this survey?',
    })
  }

  const openRecipientsSurveyModal = () => {
    openModal('recipientsSurvey', {
      modalTitle: 'Survey recipients',
    })
  }

  const openReply = (title: string, value?: string) => {
    openModal('singleInput', {
      value,
      modalTitle: title,
      label: 'Reply keyword',
      placeholder: 'Enter reply keyword please',
      message: 'Please enter a keyword that your recipient should reply to receive the next question.',
    })
  }
  const openAddReplyModal = () => openReply('Add a new reply')
  const openEditReplyModal = () => openReply('Edit reply', 'Any other reply')
  const openDeleteReplyModal = () => {
    openModal('confirmation', {
      title: 'Delete reply',
      btnText: 'Delete',
      btnColor: 'red',
      text: 'Are you sure you would like to delete this reply?',
    })
  }

  const openQuestionSurvey = (title: string, props?: any) => {
    openModal('questionSurvey', {
      modalTitle: title,
      ...props,
    })
  }
  const openAddQuestionSurveyModal = () => openQuestionSurvey('Add a new question')
  const openEditQuestionSurveyModal = () => openQuestionSurvey('Edit question', { label: 'Question 1' })
  const openDeleteQuestionSurveyModal = () => {
    openModal('confirmation', {
      title: 'Delete question',
      btnText: 'Delete',
      btnColor: 'red',
      text: 'Are you sure you would like to delete this question?',
    })
  }

  const delaySurvey = (title: string) => {
    openModal('delaySurvey', { modalTitle: title })
  }
  const openAddDelaySurveyModal = () => delaySurvey('Add delay')
  const openEditDelaySurveyModal = () => delaySurvey('Edit delay')
  const openDeleteDelaySurveyModal = () => {
    openModal('confirmation', {
      title: 'Delete delay',
      btnText: 'Delete',
      btnColor: 'red',
      text: 'Are you sure you would like to delete this delay?',
    })
  }

  return {
    openNewModal,
    openDuplicateModal,
    openDeleteModal,
    // survey flow
    openEditSurveyNameModal,
    openDuplicateSurveyModal,
    openScheduleSurveyModal,
    openPreviewSurveyModal,
    openResetSurveyModal,
    openDeleteSurveyModal,
    openRecipientsSurveyModal,
    openAddQuestionSurveyModal,
    openEditQuestionSurveyModal,
    openDeleteQuestionSurveyModal,
    openAddReplyModal,
    openEditReplyModal,
    openDeleteReplyModal,
    openAddDelaySurveyModal,
    openEditDelaySurveyModal,
    openDeleteDelaySurveyModal,
  }
}

export default useSmsSurvey
