
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmButtonMore from '@/components/shared/TmButtonMore.vue'
import type { GraphDropdownType } from '@/definitions/shared/graph/types'

export default defineComponent({
  name: 'SurveyFlowDropdown',
  components: {
    TmDropdown,
    TmDropdownItem,
    TmButtonMore,
  },
  props: {
    items: {
      type: Array as PropType<GraphDropdownType[]>,
    },
  },
})
