import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      [`graph__${_ctx.element}`]: _ctx.element,
      [`--${_ctx.direction}`]: _ctx.direction,
      [`--${_ctx.size}`]: _ctx.size,
    })
  }, null, 2))
}