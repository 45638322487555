
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import GraphDesignElements from '@/components/shared/graph/GraphDesignElements.vue'
import useSmsSurvey from '@/compositions/services/useSmsSurvey'

export default defineComponent({
  name: 'GraphBranchAddReply',
  components: {
    GraphDesignElements,
    TmButton,
  },
  setup() {
    const { openAddReplyModal } = useSmsSurvey()

    return {
      openAddReplyModal,
    }
  },
})
