
import { computed, defineComponent, ref } from 'vue'
import GraphBoard from '@/components/shared/graph/GraphBoard.vue'
import type { GraphBranchType } from '@/definitions/shared/graph/types'
import GraphBranch from '@/components/shared/graph/GraphBranch.vue'
import useSmsSurvey from '@/compositions/services/useSmsSurvey'

export default defineComponent({
  name: 'ServicesSmsSurveysFlow',
  components: { GraphBranch, GraphBoard },
  setup() {
    const {
      openRecipientsSurveyModal,
      openEditQuestionSurveyModal,
      openDeleteQuestionSurveyModal,
      openAddDelaySurveyModal,
      openEditReplyModal,
      openDeleteReplyModal,
      openEditDelaySurveyModal,
      openDeleteDelaySurveyModal,
    } = useSmsSurvey()
    const selectedRecipients = ref(false)

    const recipientsDropdown = [
      { icon: 'edit', label: 'Edit recipients', onClick: openRecipientsSurveyModal },
      { icon: 'tmi-duplicate', label: 'Copy recipients' },
      { icon: 'delete', label: 'Clear recipients', onClick: () => { selectedRecipients.value = false } },
    ]
    const questionDropdown = [
      { icon: 'edit', label: 'Edit question', onClick: openEditQuestionSurveyModal },
      { icon: 'delete', label: 'Delete question', onClick: openDeleteQuestionSurveyModal },
    ]
    const question2Dropdown = [
      { icon: 'watch_later', label: 'Add delay', onClick: openAddDelaySurveyModal },
      ...questionDropdown,
    ]
    const replyDropdown = [
      { icon: 'edit', label: 'Edit reply', onClick: openEditReplyModal },
      { icon: 'delete', label: 'Delete reply', onClick: openDeleteReplyModal },
    ]
    const delayDropdown = [
      { icon: 'edit', label: 'Edit delay', onClick: openEditDelaySurveyModal },
      { icon: 'delete', label: 'Delete delay', onClick: openDeleteDelaySurveyModal },
    ]

    const recipientsItem = computed(() =>
      !selectedRecipients.value
        ? {
            customIcon: 'tmi-people',
            name: 'Please select your survey recipients',
            dropdown: recipientsDropdown,
            rows: [
              {
                slotData: {
                  componentName: 'TmButton',
                  value: 'Select recipients',
                  attrs: {
                    class: 'mt-2',
                    color: 'primary',
                    onClick: () => {
                      selectedRecipients.value = true
                      openRecipientsSurveyModal()
                    },
                  },
                },
              },
            ],
          }
        : {
            customIcon: 'tmi-people',
            name: '34 recipients',
            dropdown: recipientsDropdown,
          }
    )
    const mainBranchItems = computed<GraphBranchType>(() => [
      recipientsItem.value,
      {
        itemType: 'question',
        name: 'Question 1',
        dropdown: questionDropdown,
        rows: [
          {
            slotData: {
              componentName: 'TmTemplate',
              attrs: {
                content: 'Hello First name! {First name} Would you like to participate in a short survey? To continue, just reply Yes.',
                class: 'font-weight-semi-bold distinct--text lh-22',
              },
            },
          },
        ],
        children: [
          [
            {
              itemType: 'reply',
              name: 'Yes',
              dropdown: replyDropdown,
            },
            {
              itemType: 'delay',
              name: 'Wait 10 minutes',
              dropdown: delayDropdown,
            },
            {
              itemType: 'question',
              name: 'Question 2',
              contentText: 'Please enter your next question here',
              dropdown: question2Dropdown,
            },
            {
              itemType: 'reply',
              name: 'Wait for a reply',
              dropdown: replyDropdown,
              nextStep: true,
            },
          ],
          [
            {
              itemType: 'reply',
              name: 'Any other reply',
              dropdown: replyDropdown,
              endStep: true,
              addReplay: true,
            },
          ],
        ],
      },
    ])

    return {
      mainBranchItems,
    }
  },
})
