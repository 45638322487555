
import { defineComponent, ref, onMounted, nextTick } from 'vue'
import TmScrollbar from '@/components/shared/TmScrollbar.vue'
export default defineComponent({
  components: { TmScrollbar },
  setup() {
    const scroll = ref()
    const board = ref()

    onMounted(() => {
      // Centering hack because of graph branch has absolute elements
      nextTick(() => {
        const { offsetWidth: scrollWidth } = board.value

        const { offsetWidth } = scroll.value.$el
        if (scrollWidth > offsetWidth) scroll.value.setScroll({ x: (scrollWidth + 8 - offsetWidth) / 2 })
      })
    })

    return {
      board,
      scroll,
    }
  },
})
